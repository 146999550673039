export const formatter = new Intl.NumberFormat('nl-NL', {
  style: 'currency',
  currency: 'EUR',
});

export const formatIntToEUR = (n: number) => formatter.format(n / 100);

export const formatDateNumeric = (date: Date) => {
  const formatter = new Intl.DateTimeFormat('nl-NL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Europe/Amsterdam',
  });

  return formatter.format(date);
};

export const formatDate = (date: Date) => {
  const dateOptions = {
    year: 'numeric' as const,
    month: 'long' as const,
    day: 'numeric' as const,
  };

  return date.toLocaleDateString('nl-NL', dateOptions);
};

export const formatTime = (date: Date) => {
  const timeOptions = {
    hour: '2-digit' as const,
    minute: '2-digit' as const,
    hour12: false,
    timeZone: 'UTC',
  };

  return date.toLocaleTimeString('nl-NL', timeOptions);
};

export const formatDateTime = (date: Date) => {
  const options = {
    year: 'numeric' as const,
    month: '2-digit' as const,
    day: '2-digit' as const,
    hour: '2-digit' as const,
    minute: '2-digit' as const,
    hour12: false,
    timeZone: 'UTC',
  };

  return date.toLocaleDateString('nl-NL', options);
};

export function prefixPathname(path: string): string {
  if (!path || typeof path !== 'string') return '/';

  return path?.startsWith('/') ? path : `/${path}`;
}

export function formatUTCDate(date = new Date()): Date {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
}
