import { VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '@av/utils';

interface BadgeProps {
  variant?: VariantProps<typeof badgeVariants>['variant'];
  size?: VariantProps<typeof badgeVariants>['size'];
  className?: string;
  children: React.ReactNode;
}

const badgeVariants = cva(
  'inline-flex items-center rounded-md border text-xs transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default: 'border-transparent bg-primary text-primary-fg shadow hover:bg-primary/80',
        light: 'bg-primary/10 text-main ring-1 ring-primary/60',
        secondary: 'border-transparent bg-secondary text-secondary-fg hover:bg-secondary/80',
        primary: 'border-primary text-primary hover:bg-primary/10',
        destructive: 'border-destructive text-destructive shadow hover:bg-destructive/10',
        outline: 'text-foreground',
      },
      size: {
        default: 'px-2.5 py-0.5',
        icon: 'flex justify-center rounded-full p-0 size-4',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  ({ variant, size, className, children, ...props }, ref) => {
    return (
      <span
        className={cn(
          badgeVariants({
            variant,
            size,
          }),
          className
        )}
        data-testid="badge"
        ref={ref}
        {...props}
      >
        {children}
      </span>
    );
  }
);

Badge.displayName = 'Badge';

export { Badge };
