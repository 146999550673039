'use client';

import { useState, useEffect } from 'react';

export const useIsPastNoon = (): boolean => {
  const [isPastNoon, setIsPastNoon] = useState<boolean>(false);

  useEffect(() => {
    const checkTime = () => {
      const now = new Date();
      const hours = now.getHours();
      setIsPastNoon(hours >= 12);
    };

    checkTime();

    const intervalId = setInterval(checkTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return isPastNoon;
};
