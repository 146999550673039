/* eslint-disable no-unused-vars */

export const GATEWAY_API_URL = process.env.GATEWAY_API_URL;
export const WEB_URL = process.env.WEB_URL;
export const TAX_HIGH = 21;
export const TAX_LOW = 9;
export const TAX_HIGH_FACTOR = TAX_HIGH / 100;
export const TAX_LOW_FACTOR = TAX_LOW / 100;
export const DAY_IN_MS = 24 * 60 * 60 * 1000;

export const daysOfWeek = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export enum Day {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export const days = [
  { day: Day.monday, label: 'Maandag' },
  { day: Day.tuesday, label: 'Dinsdag' },
  { day: Day.wednesday, label: 'Woensdag' },
  { day: Day.thursday, label: 'Donderdag' },
  { day: Day.friday, label: 'Vrijdag' },
  { day: Day.saturday, label: 'Zaterdag' },
  { day: Day.sunday, label: 'Zondag' },
];

export const addressLabels = {
  gender: 'Aanhef',
  firstName: 'Voornaam',
  middleName: 'Tussenvoegsel',
  lastName: 'Achternaam',
  phoneNumber: 'Telefoonnummer',
  email: 'E-mail',
  streetName: 'Straatnaam',
  streetNumber: 'Huisnummer',
  streetNumberAdditional: 'Toevoeging',
  postalCode: 'Postcode',
  city: 'Plaats',
  country: 'Land',
  company: 'Bedrijfsnaam',
  kvk: 'KVK-nummer',
  label: 'Label',
};
