/* eslint-disable no-unused-vars */

import React from 'react';

export enum FieldTypes {
  rich,
  text,
  input,
  media,
  size,
  align,
  buttons,
  features,
  products,
  accordion,
  alignedText,
  columns,
  ratio,
}

export enum BlockNames {
  features,
  infoBlocks,
  products,
  richContent,
  cta,
  transportCostCalculator,
  accordion,
  image,
  columns,
}

export interface ButtonValue {
  children?: string;
  href?: string;
  target?: '_blank' | '_self';
  variant?:
    | 'default'
    | 'secondary'
    | 'light'
    | 'destructive'
    | 'outline'
    | 'shadow'
    | 'ghost'
    | 'link';
  rounded?: 'default' | 'sm' | 'md';
  size?: 'default' | 'lg';
  withArrow?: boolean;
}

export interface FeatureValue {
  title: string;
  subtitle: string;
  description: string;
  button: ButtonValue;
  media: MediaValue | null;
}

export interface MediaValue {
  url: string;
  height?: number;
  width?: number;
  alt?: string;
  filepath?: string;
  id?: number;
  alignment?: 'left' | 'right' | 'center';
}

export interface AccordionValue {
  title: string;
  body: string;
}

export interface AlignedTextValue {
  text: string;
  align: string;
}

export interface InfoBlockItem {
  title: string;
  description: string;
  icon: string;
}

export interface InfoBlock {
  subtitle: string;
  title: string;
  description: string;
  item1_title: string;
  item1_description: string;
  item2_title: string;
  item2_description: string;
  item3_title: string;
  item3_description: string;
}

export type ColumnItem =
  | {
      id: string;
      type: 'image';
      value: MediaValue | null;
    }
  | {
      id: string;
      type: 'richContent';
      value: string;
    };

export type ColumnLayout =
  | '100' // Single column (100%)
  | '50/50' // Two equal columns
  | '33/66' // One-third, two-thirds
  | '66/33' // Two-thirds, one-third
  | '33/33/33' // Three equal columns
  | '25/50/25'; // Quarter, half, quarter

export interface ColumnConfig {
  id: string;
  layout: ColumnLayout;
  columns: ColumnItem[];
}

interface StringField {
  type: FieldTypes.input | FieldTypes.text | FieldTypes.rich | FieldTypes.size | FieldTypes.align;
  value: string;
}

interface ButtonsField {
  type: FieldTypes.buttons;
  value: ButtonValue[];
}

interface ProductsField {
  type: FieldTypes.products;
  value: number[];
}

interface FeaturesField {
  type: FieldTypes.features;
  value: FeatureValue[];
}

interface AccordionField {
  type: FieldTypes.accordion;
  value: AccordionValue[];
}

interface AlignedTextField {
  type: FieldTypes.alignedText;
  value: AlignedTextValue;
}

interface MediaField {
  type: FieldTypes.media;
  value: MediaValue | null;
}

interface ColumnsField {
  type: FieldTypes.columns;
  value: ColumnConfig;
}

interface RatioField {
  type: FieldTypes.ratio;
  value: '1/1' | '1/2' | '2/1' | '2/3' | '3/2' | '1/3' | '3/1';
}

export type SharedFieldTypes =
  | ButtonsField
  | FeaturesField
  | ProductsField
  | StringField
  | AccordionField
  | AlignedTextField
  | MediaField
  | ColumnsField
  | RatioField;

export type BlockFields = Record<
  string,
  SharedFieldTypes & { label: string; showOnVariants?: string[]; order?: number }
>;

export type BlockType = {
  style?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  label?: string;
  padding?: string;
  description?: string;
  previewurl?: string;
  id: string;
  uid: string;
  maxWidth?: string;
  name: BlockNames;
  fields: BlockFields;
};
