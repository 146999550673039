export function isSameDate(date1: Date | string, date2: Date | string) {
  date1 = new Date(date1);
  date2 = new Date(date2);

  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
